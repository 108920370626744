import React from "react";
import "../styles/NavBar.css";
import "../styles/SidebarNav.css";
import "../styles/About.css";

const Navbar = () => {
  return (
    <nav class="navbar navbar-expand-md navbar-dark sticky-top" style={{paddingRight: "5%"}}>
      <div class="container-fluid" style={{paddingRight: "0"}}>
        <div class="navbar-collapse collapse w-100 order-3 dual-collapse2">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item custom-nav-item-1">
              <a class="nav-link sidebar-links hover-underline-animation custom-padding" href="#about">about</a>
            </li>
            <li class="nav-item custom-nav-item-1">
              <a class="nav-link sidebar-links hover-underline-animation custom-padding" href="#experience">experience</a>
            </li>
            <li class="nav-item custom-nav-item-1">
              <a class="nav-link sidebar-links hover-underline-animation custom-padding" href="#projects">projects</a>
            </li>
            <li class="nav-item">
              <a class="nav-link sidebar-links hover-underline-animation custom-padding" href="/assets/Elias_Railis_CV.pdf" download>download cv</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;