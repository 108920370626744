import React from "react";
import "../styles/Projects.css";
import FolderOpenRoundedIcon from "@material-ui/icons/FolderOpenRounded";
import FadeInSection from "./FadeInSection";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ExternalLinks from "./ExternalLinks";

class Projects extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1"
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey
    });
  }

  render() {
    const projects = {
      "ID CHECK": {
        desc: "Created a C# library that can be used to validate a South African ID number. It currently has more than 110 downloads from NuGet.",
        techStack: "C#, .NET 6",
        link: "https://github.com/EliasRailis/id-check",
        open: "https://www.nuget.org/packages/IdCheck/"
      },
      "MY CURIOUS ROVER": {
        desc:
          `Designed and developed a website that uses NASA’s available API’s, more specifically the Astronomy Picture of the Day
          and the Mars Rover API.`,
        techStack: "Javascript, JQuery, HTML / CSS",
        link: "https://github.com/EliasRailis/my-curious-rover",
        open: "https://my-curious-rover.netlify.app/"
      },
      "WAR AND PEACE": {
        desc:
          "Created this desktop application that uses the famous novel War and Peace from Leo Tolstoy that implements functionalities like counting the number of words in the novel, and creating a sorted list of the top 50 words that are been used.",
        techStack: "C#, WinForms",
        link: "https://github.com/EliasRailis/war-and-peace"
      },
      "SORTING ALGORITHMS": {
        desc: "Created this Java console application that implements five sorting algorithms like bubble sort, insertion sort, merge sort etc.",
        techStack: "Java",
        link: "https://github.com/EliasRailis/java-sorting-algorithms",
      },
      "CRUD APPLICATION": {
        desc: "Developed this desktop application which will allow the user to create, read, update, delete and search student information that is stored in the database.",
        techStack: "C#, WinForms, SQL Server",
        link: "https://github.com/EliasRailis/crud-application",
      },
    };

    return (
      <div id="projects">
        <div className="section-header ">
          <span className="section-title">/ projects</span>
        </div>
        <div className="project-container">
          <ul className="projects-grid">
            {Object.keys(projects).map((key, i) => (
              <FadeInSection delay={`${i + 1}00ms`}>
                <li className="projects-card">
                  <div className="card-header">
                    <div className="folder-icon">
                      <FolderOpenRoundedIcon
                        style={{ fontSize: 35 }}
                      ></FolderOpenRoundedIcon>
                    </div>
                    <ExternalLinks
                      githubLink={projects[key]["link"]}
                      openLink={projects[key]["open"]}
                    ></ExternalLinks>
                  </div>

                  <div className="card-title">{key}</div>
                  <div className="card-desc">{projects[key]["desc"]}</div>
                  <div className="card-tech">{projects[key]["techStack"]}</div>
                </li>
              </FadeInSection>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Projects;
