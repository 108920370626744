import React from "react";
import { Sidenav } from "rsuite";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";

import "../styles/SidebarNav.css";
import "react-typist/dist/Typist.css";
import FadeInSection from "./FadeInSection";

const isMobile = window.innerWidth < 600;

class SidebarNav extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1",
      showDiv: false
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const scrollPosition = window.scrollY;
    const triggerPosition = 150;

    if (scrollPosition > triggerPosition) this.setState({ showDiv: true });
    else this.setState({ showDiv: false });
  }

  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey
    });
  }

  render() {
    const { expanded } = this.state;

    const links = [
      <a href="#content">/intro</a>,
      <a href="#about">/about</a>,
      <a href="#experience">/experience</a>,
      <a href="#projects">/projects</a>,
      <a href="/assets/Elias_Railis_CV.pdf" download>/download cv</a>
    ];

    return (
      <div className="sidebar-nav">
        {this.state.showDiv && (
          <div>
            {!isMobile && (
              <Sidenav
                expanded={expanded}
                defaultOpenKeys={["3", "4"]}
                activeKey={this.state.activeKey}
                onSelect={this.handleSelect}
                appearance={"subtle"}
              >
                <Sidenav.Body>
                  <div className="sidebar-links">
                    {links.map((link, i) => (
                      <FadeInSection delay={`${i + 1}0ms`}>
                        <div>{link}</div>
                      </FadeInSection>
                    ))}
                  </div>
                </Sidenav.Body>
              </Sidenav>
            )}
          </div>
        )}
        <div className="sidebar-logos" href="/">
          <a href="mailto:elias.railis97@gmail.com">
            <EmailRoundedIcon style={{ fontSize: 20 }}></EmailRoundedIcon>
          </a>
          <a href="https://github.com/EliasRailis" target="_blank">
            <GitHubIcon style={{ fontSize: 19 }}></GitHubIcon>
          </a>
          <a href="https://www.linkedin.com/in/elias-railis-a161821b3/" target="_blank">
            <LinkedInIcon style={{ fontSize: 21 }}></LinkedInIcon>
          </a>
        </div>
      </div>
    );
  }
}

export default SidebarNav;
